<template>
<div id="flow" class="pt-8 section pb-10 relative-center">

    <h2 class="text-center f-24 f-gray mb-8">施工手順</h2>
    <div class="relative-center px-2 flows">
        <div class="inline-block ">
                <div class="flow_unit" v-for="(flow,i) in flows" :key="i">
                    <div class=" flow_box relative text-center">
                        <div class="step f-white px-2 py-2 noto9 f-14 inline-block absolute ">STEP {{i+1}}</div>
                        <h3 class="f-orange mt-10 f-14">{{flow.title}}</h3>
                        <div class="inline-block text-left mt-2">
                            {{flow.text}}
                        </div>
                        <img :src="require('@/assets/img/sec10_'+(i+1)+'.svg')" :class="'absolute img'+i" />
                    </div>
                    <div class="triangle disno1500" v-if="i!=4" />
                    <div class="clear" />
                </div>
        </div>
        <div class="clear" />
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            flows: [{
                    title: "かんたん見積",
                    text: "部屋数および面積を入力すれば、おおまかな金額を確認できます"
                },
                {
                    title: "正式見積依頼",
                    text: "正式な見積りを希望する方は、必要事項を入力し送信します"
                },
                {
                    title: "お打合せ",
                    text: "説明＆正式見積を行い、納得いただいた場合は施工日を決定します"
                },
                {
                    title: "コーティング施工",
                    text: "施工を行います。室内の状況により、備品等の移動をお願いする場合があります"
                },
                {
                    title: "作業終了",
                    text: "施工完了後、すぐに室内をお使いいただけます"
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/section10.scss";
</style>
