<template>
<div id="point" class="pt-16 section relative-center pb-4">
    <v-row>
        <v-col cols="12" xl="5" lg="5" md="12" sm="12"  order="2" order-xl="1" order-lg="1" class="flex-center">
            <div class="data relative">
                <Data01 />
                <img src="@/assets/img/9999.svg" class="absolute img9999" />
            </div>
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="12" sm="12" order="1" order-xl="2" order-lg="2" >
            <div class="text-center">
                <div class="noto9  inline-block px-2 f-gray mb-6">
                    <span class="f-20 f-12-xs pr-2">Point</span><span class="f-30 f-14-xs">1</span>
                    <span class="f-blue dai f-30  f-16-xs moto7">
                        除菌･消臭効果
                    </span>
                </div>
                <div class="f-18 title">様々な病原菌やニオイ成分を<br class="dis-xs"><span class="f-22 bold point_border">強力除菌･瞬間消臭！</span></div>
            </div>
            <div class="text-center">
                <div class="mt-8 inline-block text-left">
                 弊社光触媒の除菌消臭効果は衛生用品の中でも最強を誇ります。ノロウイルスの不活性化試験においては30分間で99.99％の分解性能を発揮した他、他の衛生用品では分解が困難とされているホルムアルデヒドも短時間で無害化します。光触媒から生成される活性酸素は、圧倒的な除菌消臭力に加え多様性・万能性においても群を抜いており、有機物分解という性質上、新型ウイルスや変異型ウイルスにおいても例外なく効果を発揮することができます
                </div>
            </div>
            <div class="text-center">
            <div class="rounded kin_box inline-block mt-6">
                <div class="text-center bold">【有機物分解の対象範囲例】</div>
                <div class="mt-2 ">
                    <table class="text-left">
                        <tr>
                            <td class="text-right disno-sm">
                                <span class="bold">細菌･ウイルス：</span>
                            </td>
                            <td>
                                <span class="dis-sm"><b>細菌･ウイルス</b><br></span>
                                ノロウイルス・O-157・黄色ブドウ球菌・サルモネラ･インフルエンザ<span class="f-10">など</span><br>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-right disno-sm">
                                <span class="bold">有害物質：</span>
                            </td>
                            <td>
                                  <span class="dis-sm"><b>有害物質</b><br></span>
                                アンモニア・ PM2.5（SOx・NOx）・ホルムアルデヒド・ダイオキシン<span class="f-10">など</span><br>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
import Data01 from "@/components/data/Data01"
export default {
   
    components: {
        Data01
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/section03.scss";
</style>
