<script>
import {
    Bar
} from 'vue-chartjs';

export default {
    extends: Bar,
    name: 'Data',
    data() {
        return {
            data: {
                labels: ['0','30分後', '3時間後'],
                datasets: [{
                    label: '光触媒除菌消臭ミスト',
                        data: ['4.7', '3.7', '2.5'],
                        borderColor: 'green',
                        fill: false,
                        type: 'line',
                        lineTension: 0,
                    },
                    {
                        label: '対照群',
                        data: ['4.7','4.5', '4.5'],
                        borderColor: '#aaa',
                        fill: false,
                        type: 'line',
                        lineTension: 0,
                    },
                ]
            },
            options: {
                scales: {
                    xAxes: [{
                        ticks: {
                            display: true,
                            fontSize:'16',
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                            min: 2,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'ウイルス感染価(log TCID50/mL)',
                        },
                    }]
                },
                legend: {
                    display: true
                },
                title: {
                    display: true,
                    position: 'top',
                    fontColor: '#333',
                    fontSize:'18',
                    padding:'10',
                    text: ['インフルエンザウイルス'],
                }
            }
        }
    },
    mounted() {
        this.renderChart(this.data, this.options)
    },

}
</script>
