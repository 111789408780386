<template>
<div>
    <Slider />
    <Section01 />
    <Section11 />
    <Section12 />
    <Section02 />
    <Section03 />
    <Section04 />
    <Section05 />
    <ContactBtn />
    <Section06 />
    <ContactBtn />
    <Section07 />
    <Section08 />
    <Section09 />
    <ContactBtn />
    <Section10 />
</div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import ContactBtn from '@/components/ContactBtn.vue'
import Section01 from '@/components/Section01.vue'
import Section02 from '@/components/Section02.vue'
import Section03 from '@/components/Section03.vue'
import Section04 from '@/components/Section04.vue'
import Section05 from '@/components/Section05.vue'
import Section06 from '@/components/Section06.vue'
import Section07 from '@/components/Section07.vue'
import Section08 from '@/components/Section08.vue'
import Section09 from '@/components/Section09.vue'
import Section10 from '@/components/Section10.vue'
import Section11 from '@/components/Section11.vue'
import Section12 from '@/components/Section12.vue'

export default {
    components: {
        Slider,
        ContactBtn,
        Section01,
        Section02,
        Section03,
        Section04,
        Section05,
        Section06,
        Section07,
        Section08,
        Section09,
        Section10,
        Section11,
        Section12,
    },
}
</script>
