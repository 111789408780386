<template>
<div id="test" class="pt-16 section relative-center pb-10">
    <h1 class="f-gray text-center f-normal mb-12 f-18">実証試験結果一覧
<br>
<span class="f-09">【実施機関】一般財団法人日本食品分析センター</span>
    </h1>
    <v-row>
        <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="flex-center py-6 " v-for="i in 6" :key="i">
            <div class="graf">
     <component :is="'Data0'+i" />
            </div>
        </v-col>
  <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="flex-center py-6" v-for="i in 2" :key="i">
      <div class="graf">
     <component :is="'Data0'+(i+6)" />
      </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
import Data01 from "@/components/data/Data01"
import Data02 from "@/components/data/Data02"
import Data03 from "@/components/data/Data03"
import Data04 from "@/components/data/Data04"
import Data05 from "@/components/data/Data05"
import Data06 from "@/components/data/Data06"
import Data07 from "@/components/data/Data07"
import Data08 from "@/components/data/Data08"

export default {
    components: {
        Data01,
        Data02,
        Data03,
        Data04,
        Data05,
        Data06,
        Data07,
        Data08
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/section06.scss";
</style>
