<template>
<div class="data05">
<p><b>成分分析試験</b></p>
    <v-data-table :headers="headers" :items="desserts" :items-per-page="8" hide-default-footer class="elevation-1"></v-data-table>
</div>
</template>

<script>
export default {
    name: "data05",
    data() {
        return {
            headers: [{
                    text: '分析試験項目',
                    value: 'content'
                },
                {
                    text: '結果',
                    value: 'result'
                },
                {
                    text: '検出限界',
                    value: 'limit'
                },
                {
                    text: '方法',
                    value: 'method'
                },
            ],
            desserts: [{
                    content: '食添許可合成タール系色素',
                    result: '検出せず',
                    limit: '-',
                    method: '薄層クロマトグラフ法',
                },
                {
                    content: '食添許外合成タール系色素',
                    result: '検出せず',
                    limit: '-',
                    method: '薄層クロマトグラフ法',
                },
                {
                    content: 'メタノール',
                    result: '検出せず',
                    limit: '5ppm',
                    method: 'ガスクロマトグラフ法',
                },
                {
                    content: 'ヒ素（Asとして）',
                    result: '検出せず',
                    limit: '0.1ppm',
                    method: '原子吸光光度法',
                },
                {
                    content: '重金属（Pbとして）',
                    result: '検出せず',
                    limit: '1ppm',
                    method: '硫化ナトリウム比色法',
                },
                {
                    content: '鉛',
                    result: '検出せず',
                    limit: '0.05ppm',
                    method: '原子吸光光度法',
                },
                {
                    content: 'カドミウム',
                    result: '検出せず',
                    limit: '0.01ppm',
                    method: '原子吸光光度法',
                },
                {
                    content: '総水銀',
                    result: '検出せず',
                    limit: '0.01ppm',
                    method: '還元帰化原子吸光光度法',
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
p{
font-size:1.2rem;
margin-bottom:10px;
}
</style>
