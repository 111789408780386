import { render, staticRenderFns } from "./Section12.vue?vue&type=template&id=129a33cc&scoped=true&"
var script = {}
import style0 from "./Section12.vue?vue&type=style&index=0&id=129a33cc&prod&lang=scss&scoped=true&"
import style1 from "./Section12.vue?vue&type=style&index=1&id=129a33cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129a33cc",
  null
  
)

export default component.exports