<template>
<div class="f-09-xxs">
    <p class="title float-left"><b>眼刺激試験（ウサギ）</b></p>
    <p class="kome01">( )：対象眼結果</p>
    <div class="clear"></div>
    <table >
        <tr>
            <td rowspan="2">
                試験動物
            </td>
            <td colspan="4">
                各観察時間における合計評価点
            </td>
        </tr>
        <tr>
            <td>1時間</td>
            <td>24時間</td>
            <td>48時間</td>
            <td>72時間</td>
        </tr>
        <tr>
            <td>&#9312;</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
        </tr>
        <tr>
            <td>&#9313;</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
        </tr>
        <tr>
            <td>&#9314;</td>
            <td>2(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
        </tr>
        <tr>
            <td>平均合計評点</td>
            <td>0.7(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
            <td>0(0)</td>
        </tr>
    </table>
    <p class="kome02">
        眼刺激性の平均合計評点：＜5.0 無刺激
    </p>
</div>
</template>

<script>
export default {
    name: "data05",
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base.scss";

title {
    font-size: 1.2rem;
    float: left;
}

table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    margin: 0;
    color: #333;
}

table td {
    border: 1px solid #aaa;
    text-align: center;
    padding: 21px 22px;
}

.kome01 {
    font-size: .8rem;
    float: right;
    margin: 0;
    position: relative;
    top: 24px;
}

.kome02 {
    font-size: .8rem;
    float: left;
}

@media all and (max-width: $xxs) {
table td {
    padding: 10px;
}
}
</style>
